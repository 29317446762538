var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('Button',{staticClass:"mx-2",attrs:{"btnVariant":"yellow"},on:{"click":function($event){return _vm.openUserStoreProductModal()}}},[_vm._v(" import from WooCommerce ")]),_c('ModalVue',{ref:"WooCommerceModal",attrs:{"id":"copyUserStoreProducts","title":"<span class='px-3'>Add Products from WooCommerce</span>","size":"xl"}},[_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-4"},[_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('FilterBox',{attrs:{"search":true,"placeholder":"Search Products..."},on:{"input":function($event){return _vm.searchProductQueryHandle($event)}}})],1)]),_c('div',{staticClass:"col-12 col-lg-8 pl-lg-0"},[_c('div',{staticClass:"d-flex py-2 py-lg-0 w-100 justify-content-start flex-row align-items-center"},[(_vm.userStoreSelectedProducts.length)?_c('div',{staticClass:"px-2"},[_vm._v(" Selected Products : "+_vm._s(_vm.userStoreSelectedProducts.length)+" ")]):_vm._e(),(_vm.userStoreSelectedProducts.length)?_c('Button',{staticStyle:{"border-radius":"4px !important"},attrs:{"disabled":_vm.isLoadingSync ||
                _vm.isLoadingStoreProducts ||
                _vm.isLoadingSelectedStoreProducts,"isLoading":_vm.isLoadingSelectedStoreProducts},on:{"click":function($event){return _vm.syncSelected()}}},[_vm._v("Sync Selected")]):_vm._e()],1)])]),_c('div',{staticClass:"loader px-2"},[_c('div',{staticClass:"d-flex",class:[
            _vm.isLoadingStoreProducts
              ? 'justify-content-between'
              : 'justify-content-end',
          ]},[(_vm.isLoadingStoreProducts)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{staticStyle:{"color":"#4d1b7e"},attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Loading Products")])],1):_vm._e()])]),(_vm.isLoadingProductFirstTime)?_c('b-skeleton-table',{attrs:{"rows":5,"columns":3,"table-props":{ bordered: true, striped: true }}}):(_vm.userStoreProducts.length)?_c('div',{staticClass:"data-table"},[_c('div',{staticStyle:{"max-height":"55vh","min-height":"55vh","overflow":"auto"}},[_c('b-table',{staticClass:"leadTable",attrs:{"head-class":"text-center","tdClass":'cursor-pointer',"stickyColumn":true,"hover":"","fields":_vm.ProductsFields,"items":_vm.userStoreProducts},on:{"row-clicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"head(checkbox)",fn:function(){return [_c('b-form-checkbox',{staticClass:"checkbox selectAll",attrs:{"size":"lg","disabled":_vm.isLoadingStoreProducts || _vm.isLoadingSelectedStoreProducts},on:{"change":function($event){return _vm.shopifyProductSelectAllToggle($event)}},model:{value:(_vm.storeSelectAllCheck),callback:function ($$v) {_vm.storeSelectAllCheck=$$v},expression:"storeSelectAllCheck"}})]},proxy:true},{key:"cell(checkbox)",fn:function(data){return [_c('b-form-checkbox',{staticClass:"checkbox",attrs:{"value":data.item,"disabled":_vm.isLoadingStoreProducts || _vm.isLoadingSelectedStoreProducts,"id":data.item.id,"size":"lg"},model:{value:(_vm.userStoreSelectedProducts),callback:function ($$v) {_vm.userStoreSelectedProducts=$$v},expression:"userStoreSelectedProducts"}})]}},{key:"cell(image)",fn:function(data){return [_c('lazy-image',{key:data.item.image,staticClass:"circle-image mr-2",attrs:{"src":data.item.image
                    ? data.item.image
                    : 'https://images.quizell.com/website/default_start_page.png'}})]}},{key:"cell(title)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.title)+" ")]}}])})],1)]):[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"50vh"}},[_c('EmptyTable',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" No product found ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Time to fill your shelves or refine your search. Remember, your results will appear right here. ")]},proxy:true}])})],1)]],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }